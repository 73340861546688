import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Index from './containers/Home';
import NotFound from './containers/NotFound';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import { routes } from '../assets/scripts/routes';

/**
 * Handles ReactJS Routing
 * @param {object} argv object defining properties to use in this function
 * @param {boolean} argv.hasAlerts whether or not the route has an alert
 * @returns {Route} react routing object
 */
export default function MyRoutes({ hasAlerts }) {
    return (
        <NavBar hasAlerts={hasAlerts}>
            <Routes>
                <Route path='/' element={
                    <Index />
                }
                />
                {routes.map(r => {
                    const MyComponent = r.component;
                    if (r.authenticated) {
                        return (
                            <Route key={r.path} path={r.path} element={
                                <AuthenticatedRoute>
                                    <MyComponent />
                                </AuthenticatedRoute>
                            }
                            />
                        );
                    } else {
                        return (
                            <Route key={r.path} path={r.path}
                                element={
                                    <MyComponent />
                                }
                            />
                        );
                    }
                })}
                {/* Finally, catch all unmatched routes */}
                <Route path="*" element={
                    <NotFound />
                }
                />
            </Routes>
        </NavBar>
    );
}
